<template>
  <div class="c-product-edit">
    <c-param-create v-if="createParam" :id="pId" :show.sync="createParam" />
    <c-param-edit v-if="editParam" :id="pId" :show.sync="editParam" />
    <c-com-edit v-if="editCom" :id="pId" :show.sync="editCom" />
    <div v-show="!createParam && !editParam && !editCom" class="product-window">
      <n-card class="product-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-tabs :data="tabs" state name="nav" />
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div v-if="nav === 'product'">
          <div class="product-info">
            <n-items>
              <n-upload title="Фото товара" :value.sync="file" />
              <br>
              <img style="max-height: 150px; max-width: 150px;" :src="$form.get('image')" alt="">
              <n-input title="Название товара" v-bind="$form.input('title')"></n-input>
              <n-input title="Описание товара" v-bind="$form.input('description')"></n-input>
              <n-input title="Цена" v-bind="$form.input('price')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
        <div v-if="nav === 'params'"> 
          <div class="card-top">
            <div></div>
            <n-button @click="showCreateParam($form.get('id'))">Создать</n-button>
          </div>
          <n-table :data="params" :columns="columns">
            <template #edit="{item}">
              <n-button icon="eye" flat round @click="showEditParam(item)" />
            </template>
            <template #delete="{item}">
              <n-button icon="trash" flat @click="deleteParam(item)"></n-button>
            </template>
          </n-table>
        </div>
        <div v-if="nav === 'comments'">
          <n-table :data="comments" :columns="columnsComments">
            <template #edit="{item}">
              <n-button icon="eye" flat round @click="showEditCom(item)" />
            </template>
            <template #delete="{item}">
              <n-button icon="trash" flat @click="deleteCom(item)"></n-button>
            </template>
          </n-table>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CProductCreate',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      tabs: [
        { name: 'product', title: 'Товар', },
        { name: 'params', title: 'Параметры', },
        { name: 'comments', title: 'Комментарии', },
      ],
      columns: [
        { name: 'value', title: 'Размер', },
        { name: 'count', title: 'Количество', },
        { name: 'edit', title: '', },
        { name: 'delete', title: '', },
      ],
      columnsComments: [
        { name: 'user.fullName', title: 'Пользователь', },
        { name: 'content', title: 'Содержание', },
        { name: 'stars', title: 'Количество звезд', },
        { name: 'edit', title: '', },
        { name: 'delete', title: '', },
      ],
      params: [],
      comments: [],
      createParam: false,
      editParam: false,
      editCom: false,
      pId: null,
    }
  },
  computed: {
    nav() {
      return this.$route.query.nav
    },
  },
  watch: {
    createParam() {
      if (!this.createParam) {
        this.loadProduct()
      }
    },
    editParam() {
      if (!this.editParam) {
        this.loadProduct()
      }
    },
    editCom() {
      if (!this.editCom) {
        this.loadProduct()
      }
    },
  },
  created() {
    this.loadProduct()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    loadProduct() {
      this.$var('load', true)
      $api.products.get(this.id).with('sizes').with('comments', (c) => c.with('user')).then((response) => {
        this.$form.init(response.data.content)
        this.params = response.data.content.sizes
        this.comments = response.data.content.comments
      }).finally(() => {
        this.$var('load', false)
      })
    },
    deleteParam(item) {
      this.$var('load', true)
      $api.products.params.delete(item.id).then(() => {
      }).finally(() => {
        this.$var('load', false)
        this.loadProduct()
      })
    },
    deleteCom(item) {
      this.$var('load', true)
      $api.products.comments.delete(item.id).then(() => {
      }).finally(() => {
        this.$var('load', false)
        this.loadProduct()
      })
    },
    save() {
      this.$var('load', true)
      if (this.file) {
        $api.files.create({ file: this.file, }).then((response) => {
          $api.products.edit(this.id, {
            image: response.data.content.id,
            title: this.$form.get('title'),
            description: this.$form.get('description'),
            price: this.$form.get('price'),
          }).then(() => {
            this.closeModal()
          }).finally(() => {
            this.$var('load', false)
          })
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $api.products.edit(this.id, {
          title: this.$form.get('title'),
          description: this.$form.get('description'),
          price: this.$form.get('price'),
        }).then(() => {
          this.closeModal()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    showCreateParam(pId) {
      this.pId = pId
      this.createParam = true
    },
    showEditCom(item) {
      this.pId = item.id
      this.editCom = true
    },
    showEditParam(item) {
      this.pId = item.id
      this.editParam = true
    },
  },
}
</script>

<style lang="scss" scoped>
.c-product-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .product-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
