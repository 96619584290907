<template>
  <div class="c-order-edit">
    <div>
      <n-card class="order-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-tabs :data="tabs" state name="nav" />
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <n-tabs-content name="nav">
          <template #order>
            <div>
              <div>
                <n-items>
                  <div>Номер заказа: {{ $form.get('number') }}</div>
                  <br>
                  <div>Филиал: {{ $form.get('branch') }}</div>
                  <n-select v-if="editable" title="Статус заказа" :data="statuses" v-bind="$form.input('status', 'select')" />
                  <div v-else>Статус заказа: {{ $form.get('status.title') }}</div><br>
                  <div>Сумма заказа: {{ $form.get('total') }}</div><br>
                  <div>Дата заказа: {{ $app.date.format($form.get('createdAt'), 'date') }}</div>
                </n-items>
              </div>
              <div class="button-save">
                <n-button v-if="editable" color="primary" @click="save">Сохранить</n-button>
              </div>
            </div>
          </template>
          <template #products>
            <div>
              <div>
                <n-table :data="products" :columns="columns" :loading="$var('load')">
                  <template #image="{item}">
                    <img :src="item.image" alt="" class="image-place">
                  </template>
                </n-table>
              </div>
            </div>
          </template>
        </n-tabs-content>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'COrderEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statuses: [
        { value: 'waiting', title: 'Ожидает подтверждения', },
        { value: 'canceled', title: 'Отменён', },
        { value: 'delivering', title: 'Доставляется', },
        { value: 'finished', title: 'Завершен', },
      ],
      tabs: [
        { name: 'order', title: 'Заказ', },
        { name: 'products', title: 'Товары', },
      ],
      columns: [
        { name: 'image', title: 'Фото товара', },
        { name: 'title', title: 'Название товара', },
        { name: 'description', title: 'Описание  товара', },
        { name: 'price', title: 'Стоимость товара', },
        { name: 'count', title: 'Количество товара в заказе', },
        { name: 'size', title: 'Выбранный размер', },
      ],
      products: [],
      editable: false,
    }
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      this.$var('load', true)
      $api.orders.get(this.id).with('products').with('branch').then((response) => {
        this.$form.init({
          number: response.data.content.number,
          branch: response.data.content.branch?.title,
          total: response.data.content.total,
          createdAt: response.data.content.createdAt,
          status: $n.find(this.statuses, (e) => {
            if (e.value === response.data.content.status) {
              return e
            }
          }),
        })
        this.products = response.data.content.products
        this.editable = this.$form.get('status.value') !== 'finished'
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      $api.orders.edit(this.id, { status: this.$form.get('status.value'), }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-order-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .order-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .image-place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
}
</style>
