<template>
  <div class="c-category-create">
    <div class="category-window">
      <n-card class="category-card">
        <n-loader :loading="$var('load')" />
        <div class="btn-place">
          <n-button @click="close">X</n-button>
        </div>
        <div class="icons">
          <div v-for="item in icons" :key="item.id" class="icon" @click="selectIcon(item)">
            <div class="image-place">
              <img :src="item.src" alt="">
            </div>
            <div class="input-place">
              <input v-model="icon" :value="item" :checked="item.id === icon.id" type="radio" name="icon">
            </div>
          </div>
        </div>
        <div class="btn-place">
          <n-button @click="choose">Выбрать</n-button>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCategoryCreate',
  data() {
    return {
      icons: [],
      icon: {},
    }
  },
  created() {
    this.loadIcons()
  },
  methods: {
    selectIcon(item) {
      this.icon = item
    },
    choose() {
      this.$emit('update:value', this.icon)
      this.close()
    },
    close() {
      this.$emit('update:show', false)
    },
    loadIcons() {
      this.$var('load', true)
      $api.file.getQuizIcons().all().then((response) => {
        this.icons = response.data.content
        console.log(this.icons)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-category-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);
  .btn-place {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .icons {
    max-width: 800px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    .icon {
      border: 1px solid black;
      padding: 10px;
      border-radius: 12px;
      flex-shrink: 0;
      margin: 10px;
      .input-place {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: right;
        input {
          flex-shrink: 0;
        }
      }
      .image-place {
        width: 64px;
        height: 64px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

}
</style>
