<template>
  <div class="c-category-edit">
    <div class="category-window">
      <c-modal-message v-if="showModalMessage" title="подкатегорию" :show.sync="showModalMessage" :ok.sync="toDelete" />
      <n-card v-if="!show && !showModalMessage" class="category-card">
        <n-loader :loading="$var('load')" />
        <div class="modal-top">
          <n-tabs :data="tabs" state name="nav" class="nav-tabs" />
          <n-button class="button-close" flat @click="closeModal">X</n-button>
        </div>
        <div v-if="nav === 'category'">
          <div class="category-info">
            <n-items>
              <n-input title="Название категории" v-bind="$form.input('title')"></n-input>
              <n-select title="Доступ" :data="branches" v-bind="$form.input('access', 'select')" />
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
        <div v-if="nav === 'subCategories'">
          <n-table :data="subcategories" :columns="columns" :loading="$var('load')">
            <template #access="{item}">
              <div>{{ item.access === 'opened' ? 'Открыт' : 'Закрыт' }}</div>
            </template>
            <template #tools="{item}">
              <n-button icon="eye" flat round @click="showModal(item)" />
            </template>
            <template #delete="{item}">
              <n-button icon="trash" flat @click="showMessage(item)"></n-button>
            </template>
          </n-table>
        </div>
      </n-card>
      <c-subcategory-edit v-if="show" :id="categoryId" :show.sync="show" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCategoryEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'category', title: 'Категория', },
        { name: 'subCategories', title: 'Подкатегории', },
      ],
      category: {},
      branches: [
        { value: 'opened', title: 'Открыт', },
        { value: 'closed', title: 'Закрыт', },
      ],
      newAccess: null,
      subcategories: [],
      columns: [
        { name: 'title', title: 'Название подкатегории', },
        { name: 'access', title: 'Доступ', },
        { name: '', title: '', },
        { name: 'tools', title: '', },
        { name: 'delete', title: '', },
      ],
      show: false,
      categoryId: null,
      showModalMessage: false,
      toDelete: false,
    }
  },
  computed: {
    nav() {
      return this.$route.query.nav
    },
  },
  watch: {
    nav() {
      if (this.nav === 'category') {
        this.loadCategory()
      } else {
        this.loadSubcategories()
      }
    },
    toDelete() {
      if (this.toDelete) {
        this.deleteCategory()
      }
    },
  },
  created() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      this.$var('load', true)
      $api.categories.get(this.id).then((response) => {
        this.category = response.data.content
        this.$form.init({
          title: this.category.title,
          access: $n.find(this.branches, [ 'value', this.category.access, ]),
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      $api.categories.edit(this.id, {
        title: this.$form.get('title'),
        access: this.$form.get('access.value'),
      }).then((response) => {
        this.category = response.data.content
        this.$form.init({
          title: this.category.title,
          access: $n.find(this.branches, [ 'value', this.category.access, ]),
        })
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSubcategories() {
      this.$var('load', true)
      $api.categories.getSub(this.id).then((response) => {
        this.subcategories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    showModal(category) {
      this.show = true
      this.categoryId = category.id
    },
    showMessage(category) {
      this.toDelete = false
      this.showModalMessage = true
      this.categoryId = category.id
    },
    deleteCategory() {
      this.$var('load', true)
      $api.categories.delete(this.categoryId).then(() => {
        this.loadSubcategories()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-category-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .category-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .modal-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .nav-tabs {
      display: flex;
      align-items: center;
      justify-content: left;
    }
  }
  .category-info {
    margin-bottom: 20px;
  }
  .button-save {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
