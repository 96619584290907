export default {
  app: {
    state: {
      query: {},
      _save: [ 'query', ],
    },
    getters: {
      query(state) {
        return state.query
      },
    },
    mutations: {
      query(state, value) {
        state.query = value
      },
    },
    actions: {
      updateUser() {
        return $api.auth.info().then((response) => {
          $app.auth.user(response.data.content.user)
        })
      },
      setQuery({ state, commit, }, value) {
        commit('query', value)
      },
    },
    namespaced: true,
  },
}
