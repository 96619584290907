<template>
  <div class="c-user">
    <c-order-edit v-if="orderShow" :id="itemId" :show.sync="orderShow" />
    <c-result-full v-if="resultShow" :id="itemId" :show.sync="resultShow" />
    <div>
      <n-card class="user-card">
        <n-loader :loading="$var('load')" />
        <div class="nav-top">
          <n-tabs :data="tabs" state name="nav">
          </n-tabs>
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <n-select v-if="nav === 'access'" :key="nav" :data="accesses" title="Доступы" :value.sync="access"></n-select>
        <n-select v-if="nav === 'user'" :key="nav" :data="types" title="Менеджер" v-bind="$form.input('isAdmin', 'select')"></n-select>
        <n-tabs-content name="nav">
          <template #user>
            <div>
              <div>
                <n-items>
                  <n-input title="Имя" v-bind="$form.input('fullName')"></n-input>
                  <n-input title="Табельный номер" v-bind="$form.input('personnelNumber')" text></n-input>
                  <n-input title="Телефон" v-bind="$form.input('phone')"></n-input>
                  <n-input title="Почта" v-bind="$form.input('email')"></n-input>
                  <n-input title="ИИН" v-bind="$form.input('iin')" text></n-input>
                </n-items>
              </div>
              <div class="button-save">
                <n-button color="primary" @click="save">Сохранить</n-button>
              </div>
            </div>
          </template>
          <template #access>
            <div>
              <div class="button-save">
                <n-button color="primary" @click="saveAccess">Сохранить</n-button>
              </div>
            </div>
          </template>
          <template #orders>
            <div>
              <n-table :data="orders" :columns="ordersColumns">
                <template #status="{item}">
                  {{ orderStatuses[item.status] }}
                </template>
                <template #tools="{item}">
                  <n-button icon="eye" flat round @click="showOrder(item)" />
                </template>
              </n-table>
            </div>
          </template>
          <template #results>
            <div>
              <n-table :data="results" :columns="resultColumns">
                <template #resultType="{item}">
                  {{ resultTypes[item.resultType] }}
                </template>
                <template #status="{item}">
                  {{ resultStatuses[item.status] }}
                </template>
                <template #points="{item}">
                  {{ Math.round(item.points)+'%' }}
                </template>
                <template #createdAt="{item}">
                  {{ $app.date.format(item.createdAt , 'date') }}
                </template>
                <template #tools="{item}">
                  <n-button icon="eye" flat round @click="showResult(item)" />
                </template>
              </n-table>
            </div>
          </template>
          <template #achievements>
            <div>
              <n-table :data="achievements" :columns="achievementsColumns">
                <template #type="{item}">
                  {{ achievementsTypes[item.type] }}
                </template>
                <template #image="{item}">
                  <img :src="item.image? item.image.src : ''" alt="">
                </template>
                <template #getAt="{item}">
                  {{ $app.date.format(item.createdAt , 'date') }}
                </template>
              </n-table>
            </div>
          </template>
        </n-tabs-content>
      </n-card>
    </div>
  </div>
</template>

<script>
import iconPdf from 'assets/icon-pdf.svg'
export default {
  name: 'CUser',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      iconPdf,
      types: [
        { value: true, title: 'Да', },
        { value: false, title: 'Нет', },
      ],
      tabs: [
        { name: 'user', title: 'Пользователь', },
        { name: 'access', title: 'Доступ', },
        { name: 'orders', title: 'Заказы', },
        { name: 'results', title: 'Тестирования', },
        { name: 'achievements', title: 'Достижения', },
      ],
      ordersColumns: [
        { name: 'number', title: 'Номер заказа', },
        { name: 'status', title: 'Статус заказа', },
        { name: 'total', title: 'Итоговая сумма', },
        { name: 'tools', title: '', },
      ],
      orderStatuses: {
        waiting: 'Ожидает подтверждения',
        delivering: 'Доставляется',
        finished: 'Завершен',
      },
      accessesTypes: {
        topManager: 'Топ менеджер',
        supplyCenter: 'Отдел снабжения',
        director: 'Директор филиала',
        directorHelper: 'Помошник директора',
      },
      accesses: [],
      resultColumns: [
        { name: 'category.title', title: 'Категория КЛН', },
        { name: 'author.fullName', title: 'Аттестатор', },
        { name: 'user.fullName', title: 'Аттестируемый', },
        { name: 'correctCount', title: 'Количество правильных ответов', },
        { name: 'questionsCount', title: 'Количество вопросов', },
        { name: 'points', title: 'Результат', },
        { name: 'resultType', title: 'Тип тестирования', },
        { name: 'status', title: 'Статус тестирования', },
        { name: 'createdAt', title: 'Дата начала тестирования', },
        { name: 'tools', title: '', },
      ],
      resultTypes: {
        education: 'Обучение',
        kln: 'КЛН',
      },
      resultStatuses: {
        finished: 'Согласован',
        interrupted: 'Прерван',
        unaccepted: 'Не согласован',
      },
      orders: [],
      resultsColumns: [],
      results: [],
      file: null,
      access: {},
      orderShow: false,
      resultShow: false,
      itemId: null,
      achievements: [],
      achievementsColumns: [
        { name: 'image', title: 'Изображение', },
        { name: 'title', title: 'Заголовок', },
        { name: 'content', title: 'Описание', },
        { name: 'type', title: 'Тип', },
        { name: 'defaultReward', title: 'Награда по умолчанию', },
        { name: 'reward', title: 'Награда', },
        { name: 'data', title: 'Описание награды', },
        { name: 'getAt', title: 'Выдана', },
      ],
      achievementsTypes: {
        workYearly: 'Ежегодная награда за выслугу лет',
        work3Years: 'Награда за выслугу лет, 3 года',
        work5Years: 'Награда за выслугу лет, 5 лет',
        birthday: 'Награда за день рождения',
        month: 'Сотрудник месяца',
        quarter: 'Сотрудник квартала',
      },
      certs: [],
      diplomas: [],
      books: [],
    }
  },
  computed: {
    nav() {
      return this.$route.query.nav
    },
  },
  created() {
    this.load()
    this.loadOrders()
    this.loadResults()
    this.loadAchievements()
    this.loadDocs()
  },
  methods: {
    approve(id) {
      this.$var('load', true)
      $api.file.edit(id, { isApproved: true, }).then((response) => {
      }).finally(() => {
        this.loadDocs()
      })
    },
    remove(id) {
      this.$var('load', true)
      $api.file.delete(id).then((response) => {
      }).finally(() => {
        this.loadDocs()
      })
    },
    loadDocs() {
      this.$var('load', true)
      this.certs = []
      this.diplomas = []
      this.books = []
      $api.file.getDocs(this.id).then((response) => {
        $n.each(response.data.content, (e) => {
          switch (e.tag) {
          case 'certificate': {
            this.certs.push(e)
            break
          }
          case 'diploma': {
            this.diplomas.push(e)
            break
          }
          case 'sanitaryBook': {
            this.books.push(e)
            break
          }
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      this.$var('load', true)
      $api.users.get(this.id).with('accesses').then((response) => {
        this.$form.init(response.data.content)
        this.$form.set('isAdmin', $n.filter(this.types, (e) => {
          if (e.value === response.data.content.isAdmin) {
            return e
          }
        })[0])
        this.loadAccesses()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    saveAccess() {
      this.$var('load', true)
      $api.accesses.give(this.id, { access: this.access.value, }).then((response) => {
        this.load()
        this.loadAccesses()
        this.access = $n.filter(this.accesses, (e) => {
          if (e.value === response.data.content.title) {
            return e
          }
        })[0]
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadAchievements() {
      this.$var('load', true)
      $api.users.getUserAchievements(this.id).with('image').then((response) => {
        this.achievements = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadOrders() {
      this.$var('load', true)
      $api.users.getOrders(this.id).then((response) => {
        this.orders = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadResults() {
      this.$var('load', true)
      $api.users.getResults(this.id).with('category').with('user').with('author').then((response) => {
        this.results = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    showOrder(item) {
      this.itemId = item.id
      this.orderShow = true
    },
    showResult(item) {
      this.itemId = item.id
      this.resultShow = true
    },
    save() {
      this.$var('load', true)
      const data = {
        ...this.$form.get(),
      }
      data.isAdmin = this.$form.get('isAdmin.value')
      $api.users.edit(this.id, data).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadAccesses() {
      this.$var('load', false)
      $api.accesses.get().then((response) => {
        this.accesses = $n.map(response.data.content, (e) => {
          if (e.title === this.$form.get('accesses[0].title')) {
            this.access = {
              value: e.name,
              title: this.accessesTypes[e.title],
            }
          }
          return {
            value: e.title,
            title: this.accessesTypes[e.title],
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-user {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .docs {
    margin: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .doc {
      border-radius: 18px;
      margin-right: 15px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      font-size: 0.9em;
      a {
        color: black;
        text-decoration: none;
      }
      background-color: rgba(198, 198, 198, 0.34);
      text-align: center;
      .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .user-card {
    width: 1200px;
    height: 500px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
