export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/categories', name: 'categories', title: 'Категории', icon: 'genderless', component: () => import('pages/categories/Index'), },
      { path: '/quizzes', name: 'quizzes', title: 'Разделы', icon: 'genderless', component: () => import('pages/quizzes/Index'), },
      { path: '/results', name: 'results', title: 'Результаты', icon: 'genderless', component: () => import('pages/results/Index'), },
      { path: '/products', name: 'products', title: 'Товары', icon: 'genderless', component: () => import('pages/products/Index'), },
      { path: '/orders', name: 'orders', title: 'Заказы', icon: 'genderless', component: () => import('pages/orders/Index'), },
      { path: '/news', name: 'news', title: 'Новости', icon: 'genderless', component: () => import('pages/news/Index'), },
      { path: '/achievements', name: 'achievements', title: 'Награды', icon: 'genderless', component: () => import('pages/achievements/Index'), },
      { path: '/users', name: 'users', title: 'Пользователи', icon: 'genderless', component: () => import('pages/users/Index'), },
      { path: '/icons', name: 'icons', title: 'Иконки разделов', icon: 'genderless', component: () => import('pages/quiz-icons/Index'), },
      { path: '/notifications', name: 'notifications', title: 'Уведомления',
        icon: 'genderless', component: () => import('pages/notifications/Index'), },
    ], },
  ], },
]
