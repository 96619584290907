<template>
  <div class="c-subcategory-edit">
    <div class="category-window">
      <n-card class="category-card">
        <div class="modal-top">
          <n-button class="button-close" flat @click="closeModal">X</n-button>
        </div>
        <div class="category-info">
          <n-items>
            <n-input title="Название подкатегории" v-bind="$form.input('title')"></n-input>
            <n-select title="Доступ" :data="branches" v-bind="$form.input('access', 'select')" />
          </n-items>
        </div>
        <div class="button-save">
          <n-button color="primary" @click="save">Сохранить</n-button>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CSubcategoryEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      category: {},
      branches: [
        { value: 'opened', title: 'Открыт', },
        { value: 'closed', title: 'Закрыт', },
      ],
    }
  },
  created() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      this.$var('load', true)
      $api.categories.get(this.id).then((response) => {
        this.category = response.data.content
        this.$form.init({
          title: this.category.title,
          access: $n.find(this.branches, [ 'value', this.category.access, ]),
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      $api.categories.edit(this.id, {
        title: this.$form.get('title'),
        access: this.$form.get('access.value'),
      }).then((response) => {
        this.category = response.data.content
        this.$form.init({
          title: this.category.title,
          access: $n.find(this.branches, [ 'value', this.category.access, ]),
        })
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-category-edit {

  .category-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .modal-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
  .category-info {
    margin-bottom: 20px;
  }
  .button-save {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
