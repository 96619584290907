/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
  }
  
  files = {
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
  }
  
  categories = {
    get: (id) => $app.api.get([ 'categories*', id, ]),
    getAll: (id) => $app.api.get([ 'admin/categories*', id, ]),
    edit: (id, data) => $app.api.patch([ 'categories*', id, ]).data(data),
    getSub: (id) => $app.api.get([ 'admin/categories*/subcategories', id, ]),
    create: (data) => $app.api.post([ 'categories', ]).data(data),
    delete: (id) => $app.api.delete([ 'categories*', id, ]),
  }

  quizzes = {
    getAll: (categoryId) => $app.api.get([ 'kln/admin/categories*/quizzes', categoryId, ]),
    get: (id) => $app.api.get([ 'kln/quizzes*', id, ]),
    getByCategory: (categoryId) => $app.api.get([ 'kln/categories*/quizzes', categoryId, ]),
    edit: (id, data) => $app.api.patch([ 'kln/quizzes*', id, ]).data(data),
    create: (data) => $app.api.post([ 'kln/quizzes', ]).data(data),
    delete: (id) => $app.api.delete([ 'kln/quizzes*', id, ]),
  }

  questions = {
    get: (id) => $app.api.get([ 'kln/quizzes*/questions', id, ]),
    getOne: (id) => $app.api.get([ 'kln/questions*', id, ]),
    getByQuiz: (quizId) => $app.api.get([ 'kln/quizzes*/questions', quizId, ]),
    create: (data) => $app.api.post([ 'kln/questions', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'kln/questions*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'kln/questions*', id, ]),
  }

  products = {
    get: (id) => $app.api.get([ 'products*', id, ]),
    create: (data) => $app.api.post([ 'products', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'products*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'products*', id, ]),
    params: {
      get: (id) => $app.api.get([ 'parameters*', id, ]),
      create: (pId, data) => $app.api.post([ 'products*/parameters', pId, ]).data(data),
      edit: (id, data) => $app.api.patch([ 'parameters*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'parameters*', id, ]),
    },
    comments: {
      get: (id) => $app.api.get([ 'comments*', id, ]),
      delete: (id) => $app.api.delete([ 'comments*', id, ]),
      edit: (id, data) => $app.api.patch([ 'comments*', id, ]).data(data),
    },
  }
  
  orders = {
    get: (id) => $app.api.get([ 'orders*', id, ]),
    edit: (id, data) => $app.api.patch([ 'orders*', id, ]).data(data),
  }
  
  news = {
    get: (id) => $app.api.get([ 'news*', id, ]),
    create: (data) => $app.api.post([ 'news', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'news*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'news*', id, ]),
  }

  achievements = {
    get: (id) => $app.api.get([ 'achievements*', id, ]),
    edit: (id, data) => $app.api.patch([ 'achievements*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'achievements*', id, ]),
    create: (data) => $app.api.post([ 'achievements', ]).data(data),
    give: (id, data) => $app.api.post([ 'achievements*/users', id, ]).data(data),
  }

  notifications = {
    get: () => $app.api.get([ 'admin/notifications', ]),
  }
  
  results = {
    get: (id) => $app.api.get([ 'kln/results*', id, ]),
    delete: (id) => $app.api.delete([ 'kln/results*', id, ]),
    makeFinish: (id) => $app.api.post([ 'kln/results*/finish', id, ]),
  }
  
  file = {
    getQuizIcons: () => $app.api.get([ 'quiz-icons', ]),
    edit: (id, data) => $app.api.patch([ 'files*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'files*', id, ]),
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
    getDocs: (userId) => $app.api.get([ 'files/users*', userId, ]),
  }
  
  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    getOrders: (id) => $app.api.get([ 'users*/orders', id, ]),
    getResults: (id) => $app.api.get([ 'users*/results', id, ]),
    getUserAchievements: (id) => $app.api.get([ 'users*/achievements', id, ]),
  }
  
  accesses = {
    get: () => $app.api.get([ 'auth/accesses', ]),   
    give: (userId, data) => $app.api.post([ 'users*/give-access', userId, ]).data(data),
  }
}
