<template>
  <div class="c-news-create">
    <div>
      <n-card class="news-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div>
            <n-items>
              <n-upload title="Фото новости" :value.sync="file" />
              <n-select title="Тип новости" :data="types" v-bind="$form.input('types', 'select')" />
              <n-input title="Заголовок новости" v-bind="$form.input('title')"></n-input>
              <n-upload title="Дополнительный материал" :value.sync="addFile" />
              <c-textarea title="Содержание новости" v-bind="$form.input('content')"></c-textarea>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNewsCreate',
  data() {
    return {
      types: [
        { value: 'live', title: 'McLive', },
        { value: 'corporate', title: 'Корпоративные новости', },
        { value: 'interesting', title: 'Интересное', },
      ],
      file: null,
      addFile: null,
      readyFile: null,
      readyAddFile: null,
    }
  },
  watch: {
    file() {
      this.loadFile(this.file, 'readyFile')
    },
    addFile() {
      this.loadFile(this.addFile, 'readyAddFile')
    },
  },
  created() {
    this.$form.init({
      types: '',
      title: '',
      content: '',
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    loadFile(file, field) {
      $api.files.create({ file: file, }).then((response) => {
        this[field] = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)

      const data = {
        type: this.$form.get('types.value'),
        title: this.$form.get('title'),
        content: this.$form.get('content'),
      }

      if (this.readyFile) {
        data.image = this.readyFile.id
      }

      if (this.readyAddFile) {
        data.addFile = this.readyAddFile.id
      }

      $api.news.create(data).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-news-create {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .news-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
