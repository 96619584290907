<template>
  <div class="c-achievement-edit">
    <div>
      <n-card class="achievement-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div>
            <n-items>
              <n-select title="Тип награды (не обязательное)" :data="types" v-bind="$form.input('types', 'select')" />
              <n-input title="Заголовок награды" v-bind="$form.input('title')"></n-input>
              <n-input title="Описание награды" v-bind="$form.input('content')"></n-input>
              <n-input title="Количество баллов" v-bind="$form.input('defaultReward')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CAchievementEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      types: [
        { value: 'workYearly', title: 'Ежегодная награда за выслугу лет', },
        { value: 'work3Years', title: 'Награда за выслугу лет, 3 года', },
        { value: 'work5Years', title: 'Награда за выслугу лет, 5 лет', },
        { value: 'birthday', title: 'Награда за день рождения', },
        { value: 'month', title: 'Сотрудник месяца', },
        { value: 'quarter', title: 'Сотрудник квартала', },
      ],
    }
  },
  computed: {
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      this.$var('load', true)
      $api.achievements.get(this.id).then((response) => {
        const data = response.data.content
        this.$form.init({
          types: data.type ? $n.find(this.types, (e) => {
            if (e.value === data.type) {
              return e
            }
          }) : null,
          title: data.title,
          content: data.content,
          defaultReward: data.defaultReward,
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      $api.achievements.edit(this.id, {
        type: this.$form.get('types.value'),
        title: this.$form.get('title'),
        content: this.$form.get('content'),
        defaultReward: this.$form.get('defaultReward'),
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-achievement-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .achievement-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
