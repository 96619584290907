<template>
  <div class="c-questions-create">
    <div class="questions-window">
      <n-card class="questions-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="category-info">
            <n-input title="Название вопроса" v-bind="$form.input('title')"></n-input>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CQuestions',
  props: {
    quizId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {
    this.$form.init({
      title: '',
    })
  },
  methods: {
    showModal(item) {

    },
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      $api.questions.create({
        quizId: this.quizId,
        title: this.$form.get('title'),
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-questions-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .questions-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
