<template>
  <div class="c-param-create">
    <div class="param-window">
      <n-card class="param-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="param-info">
            <n-items>
              <n-input title="Размер" v-bind="$form.input('value')"></n-input>
              <n-input title="Количество" v-bind="$form.input('count')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CParamCreate',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.$form.init({
      value: '',
      count: null,
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      $api.products.params.create(this.id, {
        type: 'size',
        value: this.$form.get('value'),
        count: this.$form.get('count'),
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-param-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .param-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
