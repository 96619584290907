<template>
  <div class="c-result-full">
    <div>
      <c-modal-message
        v-show="showModal"
        :show.sync="showModal" full-title="Вы уверены что хотите принудительно согласовать результат?"
        @agree="agreeTest"
      />
      <n-card class="result-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <h3>Инфо аттестации</h3>
          <n-items class="info">
            <div>Категория: {{ result.category ? result.category.title : '' }}</div>
            <div>Результат: {{ Math.round(result.points) + '%' }}</div>
            <div>Дата начала тестирования: {{ $app.date.format(result.createdAt) }}</div>
            <div>Аттестатор: {{ result.author ? result.author.fullName : '' }}</div>
            <div>Табельный номер: {{ result.author ? result.author.personnelNumber : '' }}</div>
            <div>Аттестируемый: {{ result.user ? result.user.fullName : '' }}</div>
            <div>Табельный номер: {{ result.user ? result.user.personnelNumber : '' }}</div>
            <div>Филиал: {{ result.user.branch ? result.user.branch.title : '' }}</div>
            <div>Кол-во правильных ответов: {{ result.correctCount }}</div>
            <div>Кол-во вопросов: {{ result.questionsCount }}</div>
            <div>Награда: {{ result.reward }}</div>
            <div>Количество отказов: {{ result.declineCount }}</div>
            <div>Тип тестирования: {{ types[result.resultType] }}</div>
            <div>Статус тестирования: {{ statuses[result.status] }}</div>
          </n-items>
          <div v-if="result.declineCount > 2 && result.stats === 'unaccepted'" class="made-accepted" @click="showModal = true">
            Принудительное согласование
          </div>
        </div>
        <h3>Разделы</h3>
        <div class="quizzes-lint">
          <div v-for="item in quizzes" :key="item.id" class="quiz-block" @click="selectQuiz(item)">
            <div class="image-place" :class="{'active': item.id === quiz.id}">
              <div class="image-wrapper">
                <img :src="item.image" alt="">
              </div>
            </div>
            <p>{{ item.title }}</p>
          </div>
        </div>

        <div class="category-headers">
          <h3>{{ category.parent ? category.parent[0].title : '' }}</h3>
          <h4>{{ category ? category.title : '' }}</h4>
        </div>
        <ul class="answers-list">
          <li v-for="item in questions" v-if="!item.parentId" :key="item.id" class="answer-row">
            <div class="answer">
              <div class="image-place">
                <img v-if="checkState(item) === 'ignored'" :src="iconIgnored" alt="">
                <img v-else :src="checkState(item) === 'incorrect' ? iconIncorrect : iconCorrect" alt="">
              </div>
              <p class="answer-title">{{ item.title }}</p>
            </div>
            <div v-for="sub in item.subquestions" class="sub-answer">
              <div class="image-place">
                <img v-if="checkState(sub) === 'ignored'" :src="iconIgnored" alt="">
                <img v-else :src="checkState(sub) === 'incorrect' ? iconIncorrect : iconCorrect" alt="">
              </div>
              <p class="answer-title">{{ sub.title }}</p>
            </div>
          </li>
        </ul>
      </n-card>
    </div>
  </div>
</template>

<script>
import iconCorrect from 'assets/icon-correct.svg'
import iconIncorrect from 'assets/icon-incorrect.svg'
import iconIgnored from 'assets/icon-deleted.svg'

export default {
  name: 'CResultFull',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      iconIncorrect,
      iconIgnored,
      iconCorrect,
      types: {
        education: 'Обучение',
        kln: 'КЛН',
      },
      statuses: {
        finished: 'Согласован',
        interrupted: 'Прерван',
        unaccepted: 'Не подтвержден',
        declined: 'Не согласован',
      },
      columns: [
        { name: 'question.title', title: 'Вопрос', },
        { name: 'value', title: 'Ответ', },
        { name: 'ignored', title: 'Проигнорирован', },
      ],
      category: {},
      result: {},
      data: [],
      quiz: {},
      quizzes: [],
      questions: [],
      showModal: false,
    }
  },
  watch: {
    quiz() {
      this.loadQuestions()
    },
  },
  created() {
    this.loadResult()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    agreeTest() {
      this.$var('load', true)
      $api.results.makeFinish(this.id).then(() => {
        this.loadResult()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadResult() {
      this.$var('load', true)
      $api.results.get(this.id).with('user').with('category')
        .with('author').with('answers').then((response) => {
          this.result = response.data.content
          this.loadQuizzes()
          this.loadCategory()
          this.getAnswers()
        }).catch(() => {
          this.$var('load', false)
        })
    },
    getAnswers() {
      $n.map(this.result.answers, (elem) => {
        this.answers = {
          ...this.answers,
          [elem.questionId + '']: {
            value: elem.value,
            ignored: elem.ignored,
          },
        }
      })
    },
    loadQuizzes() {
      $api.quizzes.getByCategory(this.result.categoryId).then((response) => {
        this.quizzes = response.data.content
        this.quiz = this.quizzes[0]
        this.loadQuestions()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    loadQuestions() {
      this.$var('load', true)
      $api.questions.get(this.quiz.id).with('subquestions').then((response) => {
        this.questions = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadCategory() {
      $api.categories.get(this.result.categoryId).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    selectQuiz(quiz) {
      this.quiz = quiz
    },
    checkState(question) {
      if (this.answers[question.id + '']) {
        if (this.answers[question.id + ''].value && !this.answers[question.id + ''].ignored) {
          return 'correct'
        } else {
          if (this.answers[question.id + ''].ignored) {
            return 'ignored'
          } else {
            return 'incorrect'
          }
        }
      } else {
        return 'incorrect'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-result-full {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .made-accepted {
    height: 60px;
    max-width: 260px;
    background-color: red;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .info {
    padding: 5px;
    background-color: #f6f6f6;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    column-count: 2;
    max-height: 300px;
    margin-bottom: 20px;
    flex-wrap: wrap
  }

  .quizzes-lint {
    display: flex;
    overflow: auto;

    .quiz-block {
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 0.9em;
        text-align: center;
      }

      .image-place {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 12px;
        background-color: #d6d5d5;

        .image-wrapper {
          width: 44px;
          height: 44px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &.active {
          background-color: var(--primary);
        }
      }
    }
  }

  .answers-list {
    .answer-row {
      margin-top: 30px;

      .answer {
        position: relative;
        display: flex;
        align-items: center;

        .image-place {
          margin-right: 15px;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .answer-title {
          flex: 2;
        }
      }

      .sub-answer {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 35px;

        .image-place {
          margin-right: 15px;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .answer-title {
          flex: 2;
        }
      }
    }
  }

  .result-card {
    width: 1200px;
    height: 800px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
