<template>
  <div class="c-category-create">
    <div class="category-window">
      <n-card class="category-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="category-info">
            <n-items>
              <n-select title="Родитель (Не обязательно)" :data="categories" v-bind="$form.input('parent', 'select')" />
              <n-input title="Название категории" v-bind="$form.input('title')"></n-input>
              <n-select title="Доступ" :data="accesses" v-bind="$form.input('access', 'select')" />
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCategoryCreate',
  data() {
    return {
      accesses: [
        { value: 'opened', title: 'Открыт', },
        { value: 'closed', title: 'Закрыт', },
      ],
      categories: [],
    }
  },
  computed: {
  },
  created() {
    this.loadCategories()
    this.$form.init({
      title: '',
      access: '',
      parent: null,
    })
  },
  methods: {
    loadCategories() {
      this.$var('load', true)
      $api.categories.get().then((response) => {
        this.categories = $n.map(response.data.content, (el) => {
          return {
            title: el.title,
            value: el.id,
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      $api.categories.create({
        parentId: this.$form.get('parent.value'),
        title: this.$form.get('title'),
        access: this.$form.get('access.value'),
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-category-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .category-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
