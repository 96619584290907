import Vuex from 'vuex'
import BaseUi from 'nast-ui'


/**
 *
 */
export default class NastUi extends BaseUi {
    /**
     * @type {Object}
     * @protected
     */
    static _defaultConfig = {
        duration: {
            info: 3000,
            danger: 15000,
            success: 3000,
        },
    }

    /**
     * @type {Vuex}
     * @protected
     */
    _store

    /**
     * @param {Vuex} store
     * @param {Object} config
     */
    constructor(store, config = {}) {
        super(config)
        this._store = store
    }

    /**
     * @param {string} message
     * @param {string} color info|danger|success
     */
    notify(message, color) {
        this._store.dispatch('ui/notify', { message, color, })
    }

    /**
     * @param {number} id
     */
    closeNotify(id) {
        this._store.commit('ui/remove', id)
    }

    /**
     * Returns store object for vuex
     * @param {Object} config
     * @return {Object}
     */
    static store(config = {}) {
        return {
            namespaced: true,
            state: {
                notifies: {},
            },
            getters: {},
            mutations: {
                setData(state, notify) {
                    const newNotifies = { ...state.notifies, }
                    const id = notify.id || Math.random()
                    newNotifies[id] = {
                        ...notify,
                        id,
                    }
                    state.notifies = newNotifies
                },
                remove(state, id) {
                    const newNotifies = { ...state.notifies, }
                    delete newNotifies[id]
                    state.notifies = newNotifies
                },
            },
            actions: {
                notify({ state, commit, }, notify) {
                    const durations = NastUi._config('duration', config)
                    const duration = durations[notify.color] === undefined ? durations.info : durations[notify.color]
                    const id = Math.random()
                    commit('setData', { ...notify, id, })

                    if (duration !== 0) {
                        setTimeout(() => {
                            commit('remove', id)
                        }, duration)
                    }
                },
            },
        }
    }
}
