<template>
  <div class="c-quiz-edit">
    <div>
      <n-card class="quiz-card">
        <n-loader :loading="$var('load')" />
        <c-show-icons v-if="show" :show.sync="show" :value.sync="icon" />
        <div class="window-top">
          <n-button @click="closeModal">X</n-button>
        </div>
        <div>
          <div>
            <n-items>
              <div v-if="Object.keys(icon).length !== 0">
                <div class="icon-place" @click="show = true">
                  Иконка раздела
                  <div class="image-place">
                    <img :src="icon.src" alt="">
                  </div>
                </div>
              </div>
              <div v-else @click="show = true">
                Иконка раздела
                <div class="image-place">
                  <img :src="quiz.image" alt="">
                </div>
              </div>
              <n-input title="Название раздела" v-bind="$form.input('title')"></n-input>
              <n-select title="Тип" :data="types" v-bind="$form.input('type', 'select')" />
              <n-select title="Доступ" :data="accesses" v-bind="$form.input('access', 'select')" />
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CQuizEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      category: {},
      icon: {},
      show: false,
      accesses: [
        { value: 'open', title: 'Открыт', },
        { value: 'close', title: 'Закрыт', },
      ],
      types: [
        { value: 'theory', title: 'Теория', },
        { value: 'practice', title: 'Практика', },
      ],
      quiz: {},
    }
  },
  created() {
    this.loadQuiz()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    loadQuiz() {
      this.$var('load', true)
      $api.quizzes.get(this.id).then((response) => {
        this.quiz = response.data.content
        this.$form.init({
          title: this.quiz.title,
          type: $n.find(this.types, [ 'value', this.quiz.type, ]),
          access: $n.find(this.accesses, [ 'value', this.quiz.access, ]),
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      $api.quizzes.edit(this.id, {
        title: this.$form.get('title'),
        type: this.$form.get('type.value'),
        access: this.$form.get('access.value'),
        image: Object.keys(this.icon).length !== 0 ? this.icon.id : null,
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-quiz-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .window-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .quiz-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }
  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
