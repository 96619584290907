<template>
  <div class="c-com-edit">
    <div class="com-window">
      <n-card class="com-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="com-info">
            <n-items>
              <n-input title="Содержание" v-bind="$form.input('content')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CComEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.load()
    console.log(this.id)
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      this.$var('load', true)
      $api.products.comments.get(this.id).then((response) => {
        this.$form.init(response.data.content)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      $api.products.comments.edit(this.id, this.$form.get()).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-com-edit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .com-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
