<template>
  <div class="c-modal-message">
    <n-card>
      <div class="message-body">
        <h1 v-if="fullTitle.length < 1">Вы действительно хотите удалить {{ title }} ?</h1>
        <h1 v-else>{{ fullTitle }}</h1>
        <div class="message-buttons">
          <n-button flat class="button-cancel" @click="closeModal">Отменить</n-button>
          <n-button flat class="button-ok" @click="agree">Да</n-button>
        </div>
      </div>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'CModalMessage',
  props: {
    title: {
      type: String,
      default: '',
    },
    fullTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    agree() {
      this.$emit('agree')
      this.$emit('update:ok', true)
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-modal-message {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .message-body {
    h1 {
      font-size: 1.2em;
    }

    .message-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-cancel {
        width: 100px;
        background-color: red;
        color: #ffffff;
      }
      .button-ok {
        background-color: green;
        width: 100px;
        color: #ffffff;
      }
    }
  }

}
</style>
