import CPageTitle from 'components/pageTitle/Index'
import CCategoryEdit from 'components/category-edit/Index'
import CSubcategoryEdit from 'components/subcategory-edit/Index'
import CCategoryCreate from 'components/category-create/Index'
import CQuizEdit from 'components/quiz-edit/Index'
import CQuestions from 'components/questions/Index'
import CQuestionCreate from 'components/questions-create/Index'
import CQuestionEdit from 'components/questions-edit/Index'
import CModalMessage from 'components/modal-message/Index'
import CQuizCreate from 'components/quiz-create/Index'
import CProductCreate from 'components/product-create/Index'
import CProductEdit from 'components/product-edit/Index'
import CParamCreate from 'components/param-create/Index'
import CParamEdit from 'components/param-edit/Index'
import CComEdit from 'components/com-edit/Index'
import COrderEdit from 'components/order-edit/Index'
import CNewsCreate from 'components/news-create/Index'
import CNewsEdit from 'components/news-edit/Index'
import CAchievementCreate from 'components/achievement-create/Index'
import CAchievementEdit from 'components/achievement-edit/Index'
import CResultFull from 'components/result-full/Index'
import CUser from 'components/user/Index'
import CShowIcons from 'components/show-icons/Index'


export default {
  CPageTitle,
  CCategoryEdit,
  CSubcategoryEdit,
  CCategoryCreate,
  CQuizEdit,
  CQuestions,
  CQuestionCreate,
  CQuestionEdit,
  CModalMessage,
  CQuizCreate,
  CProductCreate,
  CProductEdit,
  CParamCreate,
  CParamEdit,
  CComEdit,
  COrderEdit,
  CNewsCreate,
  CNewsEdit,
  CAchievementCreate,
  CAchievementEdit,
  CResultFull,
  CUser,
  CShowIcons,
}
