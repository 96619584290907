<template>
  <div class="c-questions">
    <c-modal-message v-if="showModalMessage" title="вопрос" :show.sync="showModalMessage" :ok.sync="toDelete"/>
    <div class="questions-window">
      <c-question-create v-if="show" :quiz-id="id" :show.sync="show" />
      <c-question-edit v-if="showEdit" :id="questionId" :show.sync="showEdit" />
      <n-card v-if="!show && !showEdit && !showModalMessage" class="questions-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button @click="showModal">Добавить</n-button>
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="category-info">
            <n-table :data="questions" :columns="columns" :loading="$var('loading')">
              <template #edit="{item}">
                <n-button icon="pen" flat round @click="showModalEdit(item)"></n-button>
              </template>
              <template #delete="{item}">
                <n-button icon="trash" flat @click="showMessage(item)"></n-button>
              </template>
            </n-table>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CQuestions',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      columns: [
        { name: 'title', title: 'Название вопроса', },
        { name: 'edit', title: '', },
        { name: 'delete', title: '', },
      ],
      show: false,
      showEdit: false,
      questionId: null,
      showModalMessage: false,
      toDelete: false,
    }
  },
  watch: {
    show() {
      this.loadQuestions()
    },
    showEdit() {
      this.loadQuestions()
    },
    toDelete() {
      if (this.toDelete) {
        this.deleteQuestion()
      }
    },
  },
  created() {
    this.loadQuestions()
    this.$form.init({
      title: '',
      access: '',
      parent: null,
    })
  },
  methods: {
    loadQuestions() {
      this.$var('loading', true)
      $api.questions.getByQuiz(this.id).then((response) => {
        this.questions = response.data.content
      }).finally(() => {
        this.$var('loading', false)
      })
    },
    showModal() {
      this.show = true
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    showModalEdit(item) {
      this.questionId = item.id
      this.showEdit = true
    },
    showMessage(item) {
      this.questionId = item.id
      this.showModalMessage = true
      this.toDelete = false
    },
    deleteQuestion() {
      this.$var('load', true)
      $api.questions.delete(this.questionId).then(() => {
        this.loadQuestions()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-questions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .category-info {
    overflow: auto;
  }

  .questions-window {
    position: relative;
  }

  .questions-card {
    width: 1000px;
    height: 700px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
