<template>
  <div class="c-news-edit">
    <div>
      <n-card class="news-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div>
            <n-items>
              <n-upload title="Фото новости" :value.sync="file" />
              <br>
              <img v-if="$form.get('image')" :src="$form.get('image')" alt="">
              <n-select title="Тип новости" :data="types" v-bind="$form.input('types', 'select')" />
              <n-input title="Заголовок новости" v-bind="$form.input('title')"></n-input>
              <c-textarea title="Содержание новости" v-bind="$form.input('content')"></c-textarea>
              <div v-if="$form.get('types.value') === 'live'">
                Лайков: {{ likes }} <br>
                Дизлайков: {{ dislikes }}
              </div>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save()">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNewsEdit',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      types: [
        { value: 'live', title: 'McLive', },
        { value: 'corporate', title: 'Корпоративные новости', },
        { value: 'interesting', title: 'Интересное', },
      ],
      file: null,
      likes: null,
      dislikes: null,
    }
  },
  computed: {
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      $api.news.get(this.id).with('likesCount').with('dislikesCount').then((response) => {
        this.$form.init({
          id: response.data.content.id,
          types: $n.find(this.types, (e) => {
            if (e.value === response.data.content.type) {
              return e
            }
          }),
          title: response.data.content.title,
          content: response.data.content.content,
          image: response.data.content.image,
        })
        this.likes = response.data.content.likesCount
        this.dislikes = response.data.content.dislikesCount
      })
    },
    save() {
      this.$var('load', true)
      if (this.file) {
        $api.files.create({ file: this.file, }).then((response) => {
          $api.news.edit(this.id, {
            type: this.$form.get('types.value'),
            title: this.$form.get('title'),
            content: this.$form.get('content'),
            image: response.data.content.id,
          }).then(() => {
            this.closeModal()
          }).finally(() => {
            this.$var('load', false)
          })
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $api.news.edit(this.id, {
          type: this.$form.get('types.value'),
          title: this.$form.get('title'),
          content: this.$form.get('content'),
        }).then(() => {
          this.closeModal()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-news-edit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .news-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
