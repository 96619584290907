<template>
  <n-form-item class="n-textarea" :title="title">
    <ckeditor v-if="!text" :value="value" :editor="editor" :config="config" @input="input" @focus="focused = true" @blur="focused = false" />
    <div v-else v-html="value" />
  </n-form-item>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadAdapter from './UploadAdapter'

export default {
  name: 'NTextarea',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    title: { type: String, default: '', },
    value: { type: String, default: '', },
    input: { type: Function, default: (value) => {}, },
    text: { type: Boolean, default: false, },
    header: { type: Boolean, default: false, },
  },
  data() {
    return {
      editor: ClassicEditor,
      focused: false,
    }
  },
  computed: {
    config() {
      let config = {
        toolbar: {
          items: [],
        },
      }

      if (this.header) {
        config = {
          toolbar: {
            items: [
              'heading', '|',
              'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
              'insertTable', '|',
              'imageUpload', 'mediaEmbed', '|',
              '-',
              // 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
              'undo', 'redo',
            ],
            shouldNotGroupWhenFull: true,
          },
          table: {
            toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells', ],
          },
          extraPlugins: [ UploadAdapter.uploader, ],
          language: 'ru',
        }
      }

      return config
    },
  },
}
</script>

<style lang="scss" scoped>
.n-textarea {
  &::v-deep .ck-editor__editable {
    min-height: 120px;
  }
  textarea {
    width: 100%;
    border: solid var(--border-color);
    border-width: 0 0 1px;
    min-height: 70px;
    outline: none;
    padding: 0;
    &::placeholder {
      color: inherit;
      opacity: .3;
    }
    &:focus {
      border-color: var(--primary);
    }
  }
}
</style>
