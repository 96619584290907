<template>
  <div class="c-product-create">
    <div class="product-window">
      <n-card class="product-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="product-info">
            <n-items>
              <n-upload title="Фото товара" :value.sync="file" />
              <n-input title="Название товара" v-bind="$form.input('title')"></n-input>
              <n-input title="Описание товара" v-bind="$form.input('description')"></n-input>
              <n-input title="Цена" v-bind="$form.input('price')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CProductCreate',
  data() {
    return {
      file: null,
    }
  },
  computed: {
  },
  created() {
    this.$form.init({
      title: '',
      price: null,
      description: '',
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      if (this.file) {
        $api.files.create({ file: this.file, }).then((response) => {
          $api.products.create({
            image: response.data.content.id,
            title: this.$form.get('title'),
            description: this.$form.get('description'),
            price: this.$form.get('price'),
          }).then(() => {
            this.closeModal()
          }).finally(() => {
            this.$var('load', false)
          })
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $api.products.create({
          title: this.$form.get('title'),
          description: this.$form.get('description'),
          price: this.$form.get('price'),
        }).then(() => {
          this.closeModal()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-product-create {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .product-card {
    min-width: 700px;
    min-height: 300px;
    position: relative;
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
  }
}
</style>
